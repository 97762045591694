<template>
  <b-container fluid class="d-flex flex-column vh-100 formatter-container">
    <div
        class="alert alert-success copy-alert"
        role="alert"
        v-if="showCopyToast"
    >
      SQL copied to clipboard!
    </div>
    <b-row class="mb-3 align-items-center caption-row">
      <b-col cols="5">
        <h1 class="mb-0 app-title">SQL Query Formatter</h1>
      </b-col>
      <b-col cols="1">
        <b-form-select
            id="dialect-select"
            ref="dialectSelect"
            v-model="options.selectedDialect"
            :options="dialectOptions">
        </b-form-select>
      </b-col>
      <b-col cols="3" class="caption-buttons">
        <b-button variant="primary" @click="formatSQL" class="me-2">
          <i class="bi bi-code-slash"></i>
          Format
        </b-button>
        <b-button variant="secondary" @click="showRules">
          <i class="bi bi-list-ul"></i>
          Rules
        </b-button>
      </b-col>
      <b-col cols="3" class="text-end">
        <b-button variant="success" @click="copyToClipboard">
          <i class="bi bi-clipboard"></i>
          Copy
        </b-button>
      </b-col>
    </b-row>
    <b-row class="flex-grow-1 editor-row">
      <b-col cols="6" class="d-flex flex-column h-100 text-start">
        <div ref="editorElement" class="editor-container form-control"></div>
      </b-col>
      <b-col cols="6" class="d-flex flex-column h-100 text-start">
        <div v-if="showDialectError">
          <b-form-group
              label="Please try selecting a different dialect"
              label-for="selectedDialectAfterFormatError"
              class="change-dialect-required">
            <b-form-select
                id="selectedDialectAfterFormatError"
                v-model="options.selectedDialect"
                :options="dialectOptions"></b-form-select>
          </b-form-group>
        </div>
        <div ref="formattedElement" class="editor-container form-control"></div>
        <div class="rate-us" v-if="showRatingBlock && !showInstallButton">
          Rate us:
        </div>
        <div class="full-stars" v-if="showRatingBlock && !showInstallButton">
          <div class="rating-group">
            <input name="fst" value="5" type="radio" disabled checked/>
            <label for="fst-1" @click="saveRatingStatus">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScWUeUNtLq7F-kdcDyu9DJamttRzIloJOLcJ3-yOggeG7Xrog/viewform">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  />
                </svg>
              </a>
            </label>
            <input name="fst" id="fst-1" value="1" type="radio"/>
            <label for="fst-2" @click="saveRatingStatus">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScWUeUNtLq7F-kdcDyu9DJamttRzIloJOLcJ3-yOggeG7Xrog/viewform">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  />
                </svg>
              </a>
            </label>
            <input name="fst" id="fst-2" value="2" type="radio"/>
            <label for="fst-3" @click="saveRatingStatus">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScWUeUNtLq7F-kdcDyu9DJamttRzIloJOLcJ3-yOggeG7Xrog/viewform">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  />
                </svg>
              </a>
            </label>
            <input name="fst" id="fst-3" value="3" type="radio"/>
            <label for="fst-4" @click="saveRatingStatus">
              <a href="https://chromewebstore.google.com/detail/sql-query-formatter/bidjaiocipfpfkdkfkcijnglmcdmoeac/reviews">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  />
                </svg>
              </a>
            </label>
            <input name="fst" id="fst-4" value="4" type="radio"/>
            <label for="fst-5" @click="saveRatingStatus">
              <a href="https://chromewebstore.google.com/detail/sql-query-formatter/bidjaiocipfpfkdkfkcijnglmcdmoeac/reviews">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                  />
                </svg>
              </a>
            </label>
            <input name="fst" id="fst-5" value="5" type="radio"/>
          </div>
        </div>
        <TrySqlFormatterButton v-if="showInstallButton"/>
      </b-col>
    </b-row>
    <b-modal v-model="showRulesModal" title="Formatting Rules" size="xl">
      <div>
        <b-container fluid>
          <b-row class="mb-3">
            <b-col cols="6">
              Data Type Case
              <b-tooltip target="dataTypeTooltip" title="Converts data types to upper- or lowercase.">
                <i class="bi bi-info-circle" id="dataTypeTooltip"></i>
              </b-tooltip>
              <b-form-select id="dataTypeCase" v-model="options.dataTypeCase" :options="caseOptions"></b-form-select>
            </b-col>
            <b-col cols="6">
              Function Case
              <b-tooltip target="functionTooltip" title="Converts function names to upper- or lowercase.">
                <i class="bi bi-info-circle" id="functionTooltip"></i>
              </b-tooltip>
              <b-form-select id="functionCase" v-model="options.functionCase" :options="caseOptions"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="6">
              Identifier Case
              <b-tooltip target="identifierTooltip"
                         title="Converts identifiers to upper- or lowercase. Only unquoted identifiers are converted.">
                <i class="bi bi-info-circle" id="identifierTooltip"></i>
              </b-tooltip>
              <b-form-select id="identifierCase" v-model="options.identifierCase"
                             :options="caseOptions"></b-form-select>
            </b-col>
            <b-col cols="6">
              Keyword Case
              <b-tooltip target="keywordTooltip" title="Converts reserved keywords to upper- or lowercase">
                <i class="bi bi-info-circle" id="keywordTooltip"></i>
              </b-tooltip>
              <b-form-select id="keywordCase" v-model="options.keywordCase" :options="caseOptions"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="6">
              Dense Operators
              <b-tooltip target="operatorsTooltip" title="Decides whitespace around operators.">
                <i class="bi bi-info-circle" id="operatorsTooltip"></i>
              </b-tooltip>
              <b-form-checkbox id="denseOperators" v-model="options.denseOperators">
                Use Dense Operators
              </b-form-checkbox>
            </b-col>
            <b-col cols="6">
              Expression Width
              <b-tooltip target="expressionWidthTooltip"
                         title="Determines maximum length of parenthesized expressions.">
                <i class="bi bi-info-circle" id="expressionWidthTooltip"></i>
              </b-tooltip>
              <b-form-input id="expressionWidth" v-model="options.expressionWidth" type="number" min="10"
                            max="100"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="6">
              Logical Operator Newline
              <b-tooltip target="logicalOperatorTooltip"
                         title="Decides newline placement before or after logical operators (AND, OR, XOR).">
                <i class="bi bi-info-circle" id="logicalOperatorTooltip"></i>
              </b-tooltip>
              <b-form-select id="logicalOperatorNewline" v-model="options.logicalOperatorNewline"
                             :options="newlineOptions"></b-form-select>
            </b-col>
            <b-col cols="6">
              Place Newline Before Semicolon
              <b-tooltip target="semicolonTooltip" title="Whether to place query separator (;) on a separate line.">
                <i class="bi bi-info-circle" id="semicolonTooltip"></i>
              </b-tooltip>
              <b-form-checkbox id="newlineBeforeSemicolon" v-model="options.newlineBeforeSemicolon">
                Newline Before Semicolon
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="6">
              Use Tabs for Indentation
              <b-tooltip target="tabsTooltip" title="Uses TAB characters for indentation.">
                <i class="bi bi-info-circle" id="tabsTooltip"></i>
              </b-tooltip>
              <b-form-checkbox id="useTabs" v-model="options.useTabs">Use Tabs</b-form-checkbox>
            </b-col>
            <b-col cols="6">
              Tab Width
              <b-tooltip target="tabWidthTooltip" title="Specifies amount of spaces to be used for indentation.">
                <i class="bi bi-info-circle" id="tabWidthTooltip"></i>
              </b-tooltip>
              <b-form-input id="tabWidth" v-model="options.tabWidth" type="number" min="1" max="8"
                            :disabled="options.useTabs"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="3" md="6">
              Lines Between Queries
              <b-tooltip target="linesBetweenTooltip" title="A number of empty lines. Must be positive.">
                <i class="bi bi-info-circle" id="linesBetweenTooltip"></i>
              </b-tooltip>
              <b-form-input id="linesBetweenQueries" v-model="options.linesBetweenQueries" type="number" min="0"
                            max="100"></b-form-input>
            </b-col>
            <b-col cols="6">
              Selected Dialect
              <b-tooltip target="dialectTooltip" title="Specifies the SQL dialect to use.">
                <i class="bi bi-info-circle" id="dialectTooltip"></i>
              </b-tooltip>
              <b-form-select id="selectedDialect" v-model="options.selectedDialect"
                             :options="dialectOptions"></b-form-select>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import {format} from 'sql-formatter'
import 'bootstrap-icons/font/bootstrap-icons.css'
import {EditorView, basicSetup} from 'codemirror'
import {EditorState} from '@codemirror/state'
import {sql} from '@codemirror/lang-sql'
import TrySqlFormatterButton from "@/components/TrySqlFormatterButton.vue";

export default {
  name: 'SQLFormatter',
  components: {
    TrySqlFormatterButton,
  },
  data() {
    return {
      editor: null,
      formattedEditor: null,
      sqlQuery: 'SELECT NAME,COMPANY FROM users WHERE SQL_SKILL<=80 AND AGE>20',
      formattedQuery: '',
      showRulesModal: false,
      showDialectError: false,
      showCopyToast: false,
      ratingSubmitted: false,
      options: {
        dataTypeCase: 'preserve',
        functionCase: 'preserve',
        identifierCase: 'preserve',
        denseOperators: false,
        keywordCase: 'preserve',
        expressionWidth: 50,
        logicalOperatorNewline: 'before',
        newlineBeforeSemicolon: false,
        useTabs: false,
        tabWidth: 2,
        linesBetweenQueries: 2,
        selectedDialect: 'sql',
      },
      dialectOptions: [
        {value: 'sql', text: 'SQL'},
        {value: 'bigquery', text: 'BigQuery'},
        {value: 'db2', text: 'DB2'},
        {value: 'hive', text: 'Hive'},
        {value: 'mariadb', text: 'MariaDB'},
        {value: 'mysql', text: 'MySQL'},
        {value: 'n1ql', text: 'N1QL'},
        {value: 'plsql', text: 'PL/SQL'},
        {value: 'postgresql', text: 'PostgreSQL'},
        {value: 'redshift', text: 'Redshift'},
        {value: 'spark', text: 'Spark'},
        {value: 'sqlite', text: 'SQLite'},
        {value: 'tsql', text: 'Transact-SQL'},
      ],
      caseOptions: [
        {value: 'preserve', text: 'Preserve'},
        {value: 'upper', text: 'Uppercase'},
        {value: 'lower', text: 'Lowercase'}
      ],
      newlineOptions: [
        {value: 'before', text: 'Before'},
        {value: 'after', text: 'After'}
      ]
    }
  },
  computed: {
    isSite() {
      return process.env.VUE_APP_MODE === 'site';
    },
    showRatingBlock() {
      return !this.ratingSubmitted;
    },
    showInstallButton() {
      return this.isSite;
    }
  },
  methods: {
    formatSQL() {
      try {
        const formatted = format(this.sqlQuery, {
          language: this.options.selectedDialect,
          linesBetweenQueries: this.options.linesBetweenQueries,
          indentStyle: this.options.useTabs ? 'tabular' : 'standard',
          keywordCase: this.options.keywordCase,
          tabWidth: this.options.tabWidth,
          newlineBeforeSemicolon: this.options.newlineBeforeSemicolon,
          denseOperators: this.options.denseOperators,
          logicalOperatorNewline: this.options.logicalOperatorNewline,
          dataTypeCase: this.options.dataTypeCase,
          functionCase: this.options.functionCase,
          identifierCase: this.options.identifierCase,
          expressionWidth: this.options.expressionWidth
        })
        this.formattedQuery = formatted
        this.formattedEditor.dispatch({
          changes: {from: 0, to: this.formattedEditor.state.doc.length, insert: formatted}
        });
        this.showDialectError = false;
      } catch (error) {
        console.error('Error formatting SQL:', error)
        this.formattedQuery = error.message
        this.formattedEditor.dispatch({
          changes: {from: 0, to: this.formattedEditor.state.doc.length, insert: this.formattedQuery}
        });

        if (error.message.toLowerCase().includes('dialect')) {
          this.showDialectError = true;
          this.$nextTick(() => {
            this.$refs.dialectSelect.$el.focus();
          });
        }
      }
    },
    showRules() {
      this.showRulesModal = true;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.formattedQuery).then(() => {
        this.showCopyToast = true
        setTimeout(() => {
          this.showCopyToast = false
        }, 1000)
      }).catch(err => {
        console.error('Failed to copy text: ', err)
        alert('Failed to copy SQL. Please try again. ' + err)
      })
    },
    saveToLocalStorage() {
      localStorage.setItem('sqlQuery', this.sqlQuery)
      localStorage.setItem('options', JSON.stringify(this.options))
    },
    loadFromLocalStorage() {
      const savedQuery = localStorage.getItem('sqlQuery')
      if (savedQuery) {
        this.sqlQuery = savedQuery
      }

      const savedOptions = localStorage.getItem('options')
      if (savedOptions) {
        this.options = JSON.parse(savedOptions)
      }

      const status = localStorage.getItem('ratingSubmitted');
      this.ratingSubmitted = status === 'true';
    },
    onEditorChange(update) {
      if (update.docChanged) {
        this.sqlQuery = update.state.doc.toString();
        this.saveToLocalStorage();
      }
    },
    saveRatingStatus() {
      localStorage.setItem('ratingSubmitted', 'true');
      this.ratingSubmitted = true;
    },
  },
  mounted() {
    this.loadFromLocalStorage()

    const editorConfig = [
      basicSetup,
      sql(),
      EditorView.theme({
        "&": {height: "100%"},
        ".cm-scroller": {overflow: "auto"},
        ".cm-content": {minHeight: "100%"}
      }),
      EditorState.tabSize.of(this.options.tabWidth),
      EditorView.lineWrapping
    ];

    this.editor = new EditorView({
      doc: this.sqlQuery,
      extensions: [...editorConfig, EditorView.updateListener.of(this.onEditorChange)],
      parent: this.$refs.editorElement,
    });

    this.formattedEditor = new EditorView({
      doc: this.formattedQuery,
      extensions: editorConfig,
      parent: this.$refs.formattedElement,
    });

    if (this.isSite) {
      setTimeout(() => {
        const container = document.body.querySelector('.formatter-container');
        container.scrollIntoView({behavior: 'smooth', block: 'end'});
      }, 500);
    }
  },
  watch: {
    options: {
      handler: 'saveToLocalStorage',
      deep: true
    },
    'options.selectedDialect': function () {
      this.formatSQL();
    }
  }
}
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
}

.formatter-container {
  padding-bottom: 15px;
}

.app-title {
  text-align: left;
}

.caption-row {
  padding-top: 10px;
}

.caption-buttons {
  text-align: left;
}

.flex-grow-1 {
  display: flex;
  flex-direction: column;
}

.editor-row {
  height: calc(100vh - 85px);
}

.cm-scroller {
  overflow: auto;
}

.editor-container {
  height: 100%;
  padding: 0;
  overflow: hidden;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.editor-container:focus-within {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cm-editor {
  height: 100%;
  border-radius: 0.25rem;
}

.cm-editor .cm-scroller {
  border-radius: 0.25rem;
}

.change-dialect-required {
  color: red;
}

.copy-alert {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999; /* Ensure it is above other elements */
}

.full-stars {
  text-align: center;
}

.full-stars .rating-group {
  display: inline-flex;
}

.full-stars input {
  position: absolute;
  left: -9999px;
}

.full-stars label {
  margin: 0;
  cursor: pointer;
}

.full-stars label a svg {
  margin: 2px;
  height: 30px;
  width: 30px;
  fill: #ff8400;
  transition: fill 0.3s;
}

.full-stars input:checked ~ label a svg {
  fill: #ffc711;
}

.full-stars .rating-group:hover label a svg {
  fill: #ff8400;
}

.full-stars .rating-group input:hover ~ label a svg {
  fill: #ffc711;
}

.rate-us {
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
}

</style>