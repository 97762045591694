<template>
  <div class="install-us">
    <b-button
        :href="buttonLink"
        variant="primary"
        class="d-flex align-items-center gap-2 install-button"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          class="button-icon"
          viewBox="0 0 24 24"
      >
        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0-18 0"></path>
          <path d="M9 12a3 3 0 1 0 6 0a3 3 0 1 0-6 0m3-3h8.4m-5.802 4.5l-4.2 7.275M9.402 13.5l-4.2-7.275"></path>
        </g>
      </svg>
      Try SQL Formatter Now
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'TrySqlFormatterButton',
  props: {
    buttonLink: {
      type: String,
      default: 'https://chromewebstore.google.com/detail/sql-query-formatter/bidjaiocipfpfkdkfkcijnglmcdmoeac?utm_medium=content&utm_source=site'
    }
  }
}
</script>

<style scoped>
.install-us {
  margin-top: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.install-button {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(to bottom right, #D095F1, #9A33CC);
  color: white;
  width: auto;
  padding: 10px 20px;
  margin: 0 auto;
  transition: background 0.3s ease;
  font-size: 16px;
}

.install-button:hover {
  background: linear-gradient(to top left, #D095F1, #9A33CC);
}

.button-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

@supports not (background: linear-gradient(to top left, #D095F1, #9A33CC)) {
  .install-button:hover {
    background: #9A33CC;
  }
}
</style>