import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue3, BToastPlugin } from 'bootstrap-vue-3'
import { createRouter, createWebHashHistory } from 'vue-router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)

app.use(BootstrapVue3)
app.use(BToastPlugin)

if (process.env.VUE_APP_MODE === 'site') {
    const router = createRouter({
        history: createWebHashHistory(),
        routes: [
            {
                path: '/',
                name: 'Home',
                component: () => import('./views/HomePage.vue')
            },
            {
                path: '/about',
                name: 'About',
                component: () => import('./views/AboutPage.vue')
            },
            {
                path: '/formatter',
                name: 'SQLFormatter',
                component: () => import('./components/SQLFormatter.vue')
            }
        ]
    })

    app.use(router)
}

app.mount('#app')