<template>
  <div id="app" class="container-fluid d-flex flex-column vh-100">
    <nav v-if="isSite" class="mb-3">
      <template v-for="route in routes">
        <span v-if="$route.name === route.name" :key="route.name" class="me-2">{{ route.text }}</span>
        <router-link v-else :key="route.name" :to="route.path" class="me-2">{{ route.text }}</router-link>
        <span v-if="route.name !== 'SQLFormatter'" :key="`separator-${route.name}`">|</span>
      </template>
    </nav>
    <router-view v-if="isSite"></router-view>
    <SQLFormatter v-else />
  </div>
</template>

<script>
import SQLFormatter from './components/SQLFormatter.vue'

export default {
  name: 'App',
  components: {
    SQLFormatter
  },
  data() {
    return {
      routes: [
        {name: 'Home', path: '/', text: 'Home'},
        {name: 'About', path: '/about', text: 'About'},
        {name: 'SQLFormatter', path: '/formatter', text: 'SQL Formatter'}
      ]
    }
  },
  computed: {
    isSite() {
      return process.env.VUE_APP_MODE === 'site';
    }
  }
}
</script>

<style>
#app {
  font-family: Verdana, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}

nav {
  padding: 10px 0;
}

nav a {
  text-decoration: none;
  color: #2c3e50;
}

nav a:hover {
  text-decoration: underline;
}

nav span:not([class^="separator"]) {
  font-weight: bold;
  color: #9A33CC;
}
</style>